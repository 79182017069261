<template>
  <span v-if="showComponent" class="ui_role_span">
    <slot></slot>
  </span>
</template>

<script>
import jwtDecode from 'jwt-decode';
import { mapGetters } from 'vuex';
import { find, propEq } from 'ramda';

export default {
  props: {
    role: {
      type: String,
      required: true
    },
    accessRoles: {
      type: Array,
      required: false
    },
    exact: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      roles: [
        { name: 'Student', level: 100 },
        { name: 'User', level: 200 },
        { name: 'DepartmentAdmin', level: 300 },
        { name: 'BuildingAdmin', level: 400 },
        { name: 'CompanyAdmin', level: 500 },
        { name: 'Admin', level: 600 },
        { name: 'SuperAdmin', level: 800 }
      ]
    };
  },

  computed: {
    ...mapGetters('oidcStore', ['oidcAccessToken']),

    currentRole() {
      const role = jwtDecode(this.oidcAccessToken).role;
      if (typeof role !== 'string') {
        return this.getTopRole(role);
      } else {
        return find(propEq('name', role))(this.roles);
      }
    },
    
    selectedRole() {
      return find(propEq('name', this.role))(this.roles);
    },

    showComponent() {
      return this.currentRole.level >= this.selectedRole.level;
    }
  },

  methods: {
    getTopRole(userRoles) {
      let top;
      const temp = this.roles.sort((a, b) => (a.level < b.level ? 1 : 0));     
      temp.forEach(r => {
        userRoles.forEach(ur => {
          if (ur === r.name && !top) {
            top = r;
          }
        });
      });
      return top;
    }
  }
};
</script>

<style>
.ui_role_span {
  height: 100%;
  display: flex;
  vertical-align: center;
}
</style>
