<template>
  <div>
    <AppBackgroundImage/>
    <div class="app_breadcrumbs_wrapper">
      <p class="app_breadcrumbs_text">Du er her:</p>
      <ul class="breadcrumb_list">
        <li class="breadcrumb" v-for="crumb in getBreadcrumbs" v-bind:key="crumb.link + crumb.name">
          <router-link :to=getFullLink(crumb.link)>
            {{$t(crumb.name)}}
          </router-link>
          <p class="breadcrumb_arrow">></p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

import AppBackgroundImage from "@/components/app/AppBackgroundImage";

export default {
  name: 'AppBreadcrumbs',
  components: {AppBackgroundImage},
  data() {
    return {
    }
  },

  methods: {
    getFullLink(link) {
      if (link !== "")
      {
        return "/" + this.$i18n.locale + link;
      } else {
        return "";
      }
    }
  },



  computed: {
    getBreadcrumbs() {
      return this.$store.state.breadcrumbs;
    },

  }
};


</script>

<style scoped>
.app_top_image {
  display: flex;
  height: 15em;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: 50%;
}

.app_page {
  display: flex;
  width: 100%;
  align-content: center;
  justify-content: center;
  background: linear-gradient(to bottom, transparent 50%, #f2f2f2 0);
}

.app_breadcrumbs_wrapper {
  width: 100%;
  display: flex;
  height: 3em;
  background-color: white;
  justify-items: center;
  align-items: center;
}


.app_breadcrumbs_text {
  margin-left: 8em;
  margin-right: 0.5em;
}

@media only screen and (max-width: 1023px) {
  .app_breadcrumbs_wrapper {
    justify-content: start;
    padding-right: 1rem;
  }

  .app_breadcrumbs_text {
    margin-left: .75em;
    margin-right: 0.5em;
  }
}



.breadcrumb {
  display: inline-flex;
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.breadcrumb_arrow {
  margin-left: 0.5em;
}

.breadcrumb:last-of-type .breadcrumb_arrow {
  display: none;
}

.breadcrumb:last-of-type {
  font-weight: 600;
}
</style>
