<template>
  <router-link
    class="nav-item"
    active-class="nav-item-active"
    :to="{ name: route }"
    :exact="exact"
    @click.native="handleClick"
  >
    <p class="nav-item-text">
    <slot></slot>
    </p>
  </router-link>
</template>

<script>
export default {
  name: 'GlobalNavigationItem',

  props: {
    route: {
      type: String,
      required: true
    },
    exact: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    handleClick() {
      this.$emit('click');
    }
  }
};
</script>

<style scoped>
app_header_item {
  display: flex;
}

.nav-item {
  color: white;
  margin-right: 2em;
  display: flex;
  align-content: center;
  justify-content: center;
  justify-items: center;
  align-items: center;
}

.nav-item-active {
  color: white;
  font-weight: 700;
  border-bottom: white solid 0.2em;
}


</style>
