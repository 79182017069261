<template>
  <div class="bg-gray-200 min-h-screen app_page">
    <app-navbar></app-navbar>
    <app-breadcrumbs></app-breadcrumbs>
    <div class="container mx-auto">
      <div class="px-3 sm:px-4 py-6 sm:py-8">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import AppNavbar from '@/components/app/AppNavbar';
import AppBreadcrumbs from '@/components/app/AppBreadcrumbs';

export default {
  components: {
    AppBreadcrumbs,
    AppNavbar
  }
};
</script>

<style>
.app_page {
  display: flex;
  flex-direction: column;
  width: 100%;
}

</style>
